@charset "UTF-8";


// 미리 해놓은 퀵메뉴 - 부모 body
.main{
  .quick_wrap{
    right: 90px;
  }
}
.m_main{display: none !important;}
@media (max-width: 767px){
  .main{
    display: none !important;
  }
  .m_main{
    display: block !important;
  }
}
@media (max-width: 767px){
  .m_main{
    .container{
      padding: 0px;
    }
  }
}

.quick_wrap{
  position: fixed;
  top: 192px;
  right: 50px;
}
@media (max-width: 767px){
  .quick_wrap{
    display: none;
  }
}
.quick_right{
  border: 1px solid #EEE;
  border-radius: 7px;
  background-color: #FFF;
}
.quick_right__item{
  width: 100px;
  border-top: 1px solid #EEE;
}
.quick_right__link{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 20px 0px;
  color: #000;
  font-size: 14px;
  text-align: center;
  line-height: 1.25;

  i{
    display: inline-block;
    // width: 59px;
    // height: 60px;
    margin-bottom: 5px;
  }
}



// 메인 정해지면 새롭게
// .swiper {
//   width: 600px;
//   height: 300px;
// }



// 메인 헤더
// .header{
//   width: 1520px;
// }


// 메인 시작
.main_container_wrap{
  display: flex;
}
@media (max-width: 767px){
  .m_main{
    .main_container_wrap{
      flex-direction: column;
    }
  }
}

// 메인 sec
.main_sec_wrap+.main_sec_wrap{
  margin-left: 20px;
}
@media (max-width: 767px){
  .m_main{
    .main_sec_wrap{
      margin-left: 0px;
    }
  }
}
@media (max-width: 767px){
  .m_main{
    .m_sec_wrap+.m_sec_wrap{
      padding: 20px 8px 0px;
    }
  }
}

// 배너
.main_banner_wrap{

}
.main_banner_txt{
  position: absolute;
  top: 50%;
  right: 0px;
  // width: 560px;
  height: 200px;
  padding: 30px 30px 40px;
  background: rgba(0, 0, 0, 0.52);
  transform: translateY(-50%);

  h2{
    margin: 0px 0px 30px;
    font-family: 'NanumSquare';
    font-size: 42px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.84px;
    color: #7CE8C1;
    text-align: left;
  }
  h3{
    margin: 0px;
    font-family: "NotoSansKR";
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.68px;
    color: #FFF;
    text-align: left;
  }
}
@media (max-width: 767px){
  .m_main{
    .main_banner_txt{
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 300px;
      width: 50%;
      height: 165px;
      top: 50%;
      padding: 20px;
      transform: translateY(-50%);
      text-align: left;

      h2{
        margin-bottom: 16px;
        font-size: 22px;
        line-height: 22px;
      }
      h3{
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

// 자료
.main_reference_wrap{
  display: flex;
  margin-top: 20px;
}
@media (max-width: 767px){
  .m_main{
    .main_reference_wrap{
      flex-direction: column;
      margin-top: 10px;
    }
  }
}
// 자료 - 영상자료



// 영상자료
.main_video_wrap{
  width: 500px;
  border: 1px solid #E3E1E1;
  border-radius: 20px;
  padding: 20px;
  background: #FFFFFF;
  overflow: hidden;
}
@media (max-width: 767px){
  .m_main{
    .main_video_wrap{
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
.sec_video_hd{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sec_video_title,
.sec_notice_title{
  margin: 0px;
  font-family: "NotoSansKR";
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}
.sec_video_content{
  margin-top: 14px;
}
.main_video_link{
  display: flex;
}
@media (max-width: 767px){
  .m_main{
    .main_video_link{
      flex-direction: column;
      align-items: center;
    }
  }
}
.main_video_txt{
  margin-left: 15px;
  font-family: "NotoSansKR";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  color: #000;
  text-align: left;
}
@media (max-width: 767px){
  .m_main{
    .main_video_txt{
      text-align: center;
      margin-top: 10px;
      margin-left: 0px;
    }
  }
}

// 지식샘터
.main_edunet_wrap{
  position: relative;
  margin-left: 20px;
  overflow: hidden;
}
@media (max-width: 767px){
  .m_main{
    .main_edunet_wrap{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 0px;
      border-radius: 20px;
      background-color: #F2F2F2;
    }
  }
}
.main_edunet{
  display: flex;
  width: 540px;
}
@media (max-width: 767px){
  .m_main{
    .main_edunet{
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}
.edunet_wrap{
  &::before{
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    width: 242px;
    height: 209px;
    background: url("../images/page/main/sec01_con2_bg.png") no-repeat top right;
  }
}
@media (max-width: 767px){
  .m_main{
    .edunet_wrap{
      &::before{
        display: block;
        width: 200px;
        height: 200px;
        background: url("../images/page/main/sec01_con2_bg.png") no-repeat center right;
        background-size: contain;
      }
    }
  }
}
.edunet_wrap,
.notice_wrap{
  display: flex;
  flex-direction: column;
  width: 540px;
  height: 240px;
  border-radius: 20px;
  padding: 36px 0px 20px 40px;
  background-color: #F2F2F2;
}
@media (max-width: 1279px){
  .m_main{
    .edunet_wrap,
    .notice_wrap{
      width: 100%;
    }
    .edunet_wrap{
      padding-left: 20px;
    }
  }
}
.edunet_img{
  width: 156px;
  height: 40px;
}
.edunet_txt{
  margin: 12px 0px 25px;
  font-family: "NotoSansKR";
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.28px;
  color: #000;
  text-align: left;

  p{
    margin: 7px 0px;

    &:first-child, &:last-child{
      margin-top: 0px;
    }
  }
}
@media (max-width: 1279px){
  .m_main{
    .edunet_txt{
      width: 50vw;
      margin: 20px 0px 8px;
      z-index: 1;
    }
  }
}
.notice_wrap{
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}
.notice_txt{
  display: flex;
  width: 500px;
  margin: 20px 0px 40px;
  border-top: 1px solid #ddd;
  padding: 20px 0px 0px 20px;
  color: #000;
  font-family: "NotoSansKR";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 133.333% */
  letter-spacing: -0.42px;
  text-align: left;

  &::before{
    content: "";
    flex: 0 0 37px;
    display: block;
    width: 37px;
    height: 37px;
    margin-right: 10px;
    background: url("../images/page/main/ic_notice.png") no-repeat 0px 0px;
  }
}
@media (max-width: 1279px){
  .m_main{
    .notice_txt{
      width: 100%;
      margin-bottom: 45px;
      font-size: 16px;
      padding: 20px 0px 0px;
    }
  }
}
.ic_notice{
  display: inline-block;
}

// main_sec02
.sec_border{
  display: flex;
  flex-direction: column;
  width: 440px;
  min-height: 644px;
  border: 1px solid #E3E1E1;
  border-radius: 20px;
  padding: 28px 20px 22px 24px;
  background: #FFFFFF;
  overflow: hidden;
}
@media (max-width: 767px){
  .m_main{
    .sec_border{
      width: 100%;
      min-height: auto;
    }
  }
}
.main_noti_title_wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E3E1E1;
  padding-bottom: 26px;
}
@media (max-width: 767px){
  .m_main{
    .main_noti_title_wrap{
      padding-bottom: 14px;
    }
  }
}
.main_noti_title{
  font-family: "NotoSansKR";
  font-weight: 600;
  font-size: 20px;
  color: #111111;
  margin: 0px;
  line-height: 20px;
}
.main_notice_wrap{
  min-height: 480px;
  padding: 12px 0px 16px;
}
.main_noti__item{
  border-bottom: 1px solid #E3E1E1;
  padding: 12px 0px;

  &:first-child{
    padding-top: 0px;
  }
}
.main_noti__sub_wrap{
  display: flex;
}
.main_noti__item_subtitle{
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  
  &+.main_noti__item_subtitle{
    margin-left: 8px;
  }
  &::after{
    content: "";
    display: inline-block;
    width: 1px;
    height: 14px;
    margin-left: 8px;
    background-color: #D9D9D9;
  }
  &:last-child::after{
    display: none;
  }
}
.main_noti__wrap{
  margin-top: 6px;
}
.main_noti__item_title{
  margin: 0px 0px 8px;
  font-family: "NotoSansKR";
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 16px;
}
.main_noti__link{
  width: 100%;
}
.main_noti__item_info{
  display: flex;
  align-items: center;
}
.noti__item_label{
  &::before{
    content: "연수중";
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    // height: 1.3rem;
    // line-height: 1.3rem;
    border: 1px solid #29BA6C;
    border-radius: 30px;
    padding: 3px 0px;
    color: #FFFFFF;
    background-color: #29BA6C;
    font-family: "Noto Sans KR";
    font-size: 12px;
    font-weight: 600;
  }

  &._end{
    &::before{
      content: "신청마감";
      background-color: #767676;
      border: 1px solid #767676;
    }
  }
  &._expected{
    &::before{
      content: "개설예정";
      color: #29BA6C;
      background-color: #FFFFFF;
    }
  }
  &._dead{
    &::before{
      content: "종료";
      background-color: #505050;
      border: 1px solid #505050;
    }
  }
  &._possibility{
    &::before{
      content: "신청가능";
      color: #FFFFFF;
      border: 1px solid #5A67DC;
      background-color: #5A67DC;
    }
  }
}
.noti__item_label + .noti__item_date{
  margin-left: 8px;
}
.noti__item_date{
  color: #969696;
  font-size: 0.8rem;
  line-height: 0.8rem;
  font-family: "NanumGothic";
  font-weight: 400;
}
.main_noti_ft{
  position: relative;
  top: 15px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1279px){
  .m_main{
    .main_noti_ft{
      top: 5px
    }
  }
}




// 버튼
.btn_plus{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 20px;
  border: 1px solid #E3E1E1;
  border-radius: 20px;
  font-weight: 600;
  font-size: 8px;
  color: #BCBCBC;
  background-color: #fff;

  &::before{
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    background: url("../images/common/ic_plus.png") no-repeat center;
    margin-right: 2px;
  }
}
.btn__edunet{
  width: 120px;
  height: 40px;
  border: 1px solid #767676;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  color: #666666;
  background: transparent;
  z-index: 1;
}
.btn__notice{
  width: 120px;
  height: 40px;
  margin-left: 20px;
  border: 1px solid #5A55A7;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  background: #5A55A7;
  z-index: 1;
}
@media (max-width: 1279px){
  .m_main{
    .btn__edunet,
    .btn__notice{
      height: 35px;
    }
    .btn__notice{
      margin-left: 0px;
    }
  }
}



// 메인 퀵메뉴
.quick_wrap{
  position: fixed;
  top: 130px;
  right: 50px;
  margin-right: 0px;
  border: 1px solid #E3E1E1;
  border-radius: 20px;
  padding: 20px 5px 0px;
  background-color: #fff;
  text-align: center;

  .quick_right{
    border: 0px;
  }
}
.quick_title{
  margin: 0;
  border-bottom: 1px solid #E3E1E1;
  padding-bottom: 12px;
  font-weight: 600;
  font-size: 12px;
  color: #BBBBBB;
}
.quick_right__item{
  width: initial;
  font-weight: 500;
  font-size: 12px;
  color: #000000;

  i{
    margin-bottom: 8px;
  }

  &:first-child{
    border-top: none;
  }
}
// 퀵메뉴 - 라이트바 - 아이콘
.quick_right__img01{background-image: url("../images/common/ic_right01.png"); width: 28px; height: 32px;} // 운영연수안내
.quick_right__img02{background-image: url("../images/common/ic_right02.png"); width: 36px; height: 28px;} // 발급신청
.quick_right__img03{background-image: url("../images/common/ic_right03.png"); width: 32px; height: 30px;} // FAQ
.quick_right__img04{background-image: url("../images/common/ic_right04.png"); width: 32px; height: 28px;} // 영상자료
.quick_right__img05{background-image: url("../images/common/ic_right05.png"); width: 32px; height: 28px;} // 청렴게시판
.quick_right__img10{background-image: url("../images/common/ic_right06.png"); width: 32px; height: 32px;} // 이수자 나눔공간
.quick_right__img06{background-image: url("../images/common/ic_right07.svg"); width: 42px; height: 28px;} // 터치 교사단 신청








// 메인 푸터
@media (max-width: 767px){
  .m_main{
    .footer_wrap{
      margin-top: 20px;
    }
  }
}




// 메인 배너 swiper
.swiper {
  width: 1060px;
  height: 100%;
}
@media (max-width: 767px){
  .swiper{
    width: 100%;
    height: 250px;
  }
}
.swiper-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  background: #fff;

  img{
    display: block;
    width: 100%;
    height: 384px;
    border-radius: 20px;
    object-fit: cover;
  }
}
@media (max-width: 767px){
  .swiper-slide {
    img{
      height: 100%;
      border-radius: 0px;
    }
  }
}
.swiper-pagination-bullets.swiper-pagination-horizontal{
  bottom: 21px;
  left: -18px;
  text-align: right;
}
.swiper-pagination-bullet{
  width: 10px;
  height: 10px;
  background-color: #F5F5F5;
  opacity: 1;

  &.swiper-pagination-bullet-active{
    background-color: #46B1FF;
  }
}


// 메인 영상자료 swiper
.mainVideo,
.mainEdunet{
  position: relative;
}
@media (max-width: 767px){
  .m_main{
    .mainVideo{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
.mainVideo .swiper-slide img {
  display: block;
  width: 280px;
  height: 100%;
  border-radius: 0px;
  object-fit: initial;
}
@media (max-width: 767px){
  .m_main{
    .mainVideo .swiper-slide img{
      width: 100%;
    }
  }
}
.mainVideo .swiper-pagination-fraction,
.mainNoti .swiper-pagination-fraction,
.mainEdunet .swiper-pagination-fraction{
  color: #666;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 0.24px;
  background-color: transparent;
}
.swiper_btn{
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 90px;
  height: 20px;
  z-index: 301;
}
@media (max-width: 767px){
  .m_main{
    .mainVideo,
    .mainEdunet{
      .swiper_btn{
        position: relative;
        bottom: 0;
        margin-top: 15px;
      }
    }
  }
}
.mainVideo .swiper-button-next,
.mainNoti .swiper-button-next,
.mainEdunet .swiper-button-next{
  top: 18px;
  right: 0px;
  width: 20px;
  height: 20px;
  background: url("../images/page/main/vd_btn_next.png") no-repeat center transparent;
  background-color: transparent !important;
  z-index: 302;

  &::after{
    display: none;
  }
}
.mainVideo .swiper-button-prev,
.mainNoti .swiper-button-prev,
.mainEdunet .swiper-button-prev{
  top: 18px;
  left: 0px;
  width: 20px;
  height: 20px;
  background: url("../images/page/main/vd_btn_prev.png") no-repeat center;
  background-color: transparent !important;
  z-index: 302;

  &::after{
    display: none;
  }
}

// 메인 - 연수신청안내
.mainNoti .swiper-slide{
  position: relative;
  display: block;
}
.mainNoti .swiper_btn{
  position: relative;
}
.mainEdunet .swiper_btn{
  bottom: 20px;
  right: 20px;
}

// 메인 - 지식샘터
.mainEdunet{
  .swiper-slide{
    background-color: transparent;
  }
  .swiper-slide{
    justify-content: flex-start;
    align-items: flex-start;

    .edunet_img{
      width: 156px;
      height: 40px;
      border-radius: 0px;
    }
  }

  .swiper-pagination-bullet{
    background-color: #fff;

    &.swiper-pagination-bullet-active{
      background-color: #46B1FF;
    }
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal{
    width: 50px;
    left: initial;
    right: 20px;
  }
}
@media (max-width: 767px){
  .m_main{
    .mainEdunet{
      .swiper_btn{
        bottom: 7px;
        right: 20px;
      }
    }
  }
}



// 메인 검색창
.main_search_wrap{
  position: relative;
  margin-left: auto;

  @media (max-width: 767px){
    margin-left: 0px;
  }
}
input.main_search_bar{
  width: 280px;
  height: 36px;
  border-radius: 30px;
  border: 1px solid rgba(227, 225, 225, 0.60);
  padding: 10px 50px 10px 20px;
  background: #FFF;
  color: #191919;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: -0.32px;
  box-shadow: 0px 0px 8px 0px rgba(15, 7, 62, 0.10);

  @media (max-width: 767px){
    display: none;
  }
}
.search__btn{
  position: absolute;
  top: 50%;
  right: 0px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url("../images/common/ic_view_wt.png") no-repeat center #023C73;
  transform: translateY(-50%);

  @media (max-width: 767px){
    position: static;
    transform: none;
    border-radius: 0px;
    background: url("../images/common/ic_view_blue.svg") no-repeat center transparent;
  }
}