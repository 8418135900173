// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$ic-bus-name: 'ic_bus';
$ic-bus-x: 0px;
$ic-bus-y: 0px;
$ic-bus-offset-x: 0px;
$ic-bus-offset-y: 0px;
$ic-bus-width: 22px;
$ic-bus-height: 22px;
$ic-bus-total-width: 72px;
$ic-bus-total-height: 48px;
$ic-bus-image: '../images/sprite_out/sprite_all.png';
$ic-bus: (0px, 0px, 0px, 0px, 22px, 22px, 72px, 48px, '../images/sprite_out/sprite_all.png', 'ic_bus', );
$ic-car-name: 'ic_car';
$ic-car-x: 26px;
$ic-car-y: 0px;
$ic-car-offset-x: -26px;
$ic-car-offset-y: 0px;
$ic-car-width: 22px;
$ic-car-height: 22px;
$ic-car-total-width: 72px;
$ic-car-total-height: 48px;
$ic-car-image: '../images/sprite_out/sprite_all.png';
$ic-car: (26px, 0px, -26px, 0px, 22px, 22px, 72px, 48px, '../images/sprite_out/sprite_all.png', 'ic_car', );
$ic-speaker-name: 'ic_speaker';
$ic-speaker-x: 52px;
$ic-speaker-y: 0px;
$ic-speaker-offset-x: -52px;
$ic-speaker-offset-y: 0px;
$ic-speaker-width: 20px;
$ic-speaker-height: 16px;
$ic-speaker-total-width: 72px;
$ic-speaker-total-height: 48px;
$ic-speaker-image: '../images/sprite_out/sprite_all.png';
$ic-speaker: (52px, 0px, -52px, 0px, 20px, 16px, 72px, 48px, '../images/sprite_out/sprite_all.png', 'ic_speaker', );
$ic-subway-name: 'ic_subway';
$ic-subway-x: 0px;
$ic-subway-y: 26px;
$ic-subway-offset-x: 0px;
$ic-subway-offset-y: -26px;
$ic-subway-width: 22px;
$ic-subway-height: 22px;
$ic-subway-total-width: 72px;
$ic-subway-total-height: 48px;
$ic-subway-image: '../images/sprite_out/sprite_all.png';
$ic-subway: (0px, 26px, 0px, -26px, 22px, 22px, 72px, 48px, '../images/sprite_out/sprite_all.png', 'ic_subway', );
$ic-train-name: 'ic_train';
$ic-train-x: 26px;
$ic-train-y: 26px;
$ic-train-offset-x: -26px;
$ic-train-offset-y: -26px;
$ic-train-width: 22px;
$ic-train-height: 22px;
$ic-train-total-width: 72px;
$ic-train-total-height: 48px;
$ic-train-image: '../images/sprite_out/sprite_all.png';
$ic-train: (26px, 26px, -26px, -26px, 22px, 22px, 72px, 48px, '../images/sprite_out/sprite_all.png', 'ic_train', );
$spritesheet-width: 72px;
$spritesheet-height: 48px;
$spritesheet-image: '../images/sprite_out/sprite_all.png';
$spritesheet-sprites: ($ic-bus, $ic-car, $ic-speaker, $ic-subway, $ic-train, );
$spritesheet: (72px, 48px, '../images/sprite_out/sprite_all.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
