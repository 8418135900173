@charset "UTF-8";

// 공통 영역
body{
  position: relative;
}
ul{
  margin: 0px;
  padding: 0px;
}
button, [type="button"], [type="reset"], [type="submit"]{
  border: none;
  line-height: normal;
}
:disabled{
  color: #999 !important;
  background-color: #eee !important;
  cursor: no-drop !important;
  opacity: 0.7 !important;
}
img{
  vertical-align: top;
}
.justifyContent{
  justify-content: center;
}
i{
  display: inline-block;
}



// input 시작
// 텍스트, 숫자, 비밀번호
input{padding-left: 10px;}
input[type=text],
input[type=number],
input[type=password]{
  width: 240px;
  height: 36px;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  color: #999999;
  background: #FFFFFF;
}
@media (max-width: 767px){
  input[type=text],
  input[type=number],
  input[type=password],
  input[type=date]{
    width: 100% !important;
  }
}
label{
  font-weight: 600;
  font-size: 14px;
  color: #111111;
}
// placeholder
input[type=text]::placeholder,
input[type=number]::placeholder,
input[type=password]::placeholder{
  font-weight: 400;
  font-size: 13px;
  color: #999999;
}
// 달력
input[type=date]{
  width: 160px;
  height: 36px;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  color: #333333;
  background: #FFFFFF;
}
//체크박스
input[type=checkbox]{
  position: relative;
  width: 12px;
  height: 12px;
  appearance: none;
  border: 1px solid #DDDDDD;
  border-radius: 2px;
  cursor: pointer;
  background: #FFFFFF;
}
input[type=checkbox]::before{
  content: "";
}
input[type=checkbox]:checked::before{
  content: "\2713";
  position: absolute;
  top: -1px;
  left: -1px;
  right: 0px;
  bottom: 0px;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  font-size: 12px;
  vertical-align: top;
  text-align: center;
  color: #FFFFFF;
  background-color: #0059AE;
}
// 라디오
.detail_table_radiobox {
  position: relative;
}
input[type=radio] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
input[type=radio]+label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-weight: 600;
  font-size: 14px;
  color: #111111;
}
input[type=radio]+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  width: 16px;
  height: 16px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #C3C3C3;
  border-radius: 100%;
}
input[type=radio]:checked+label:before {
  background: #FFFFFF;
  border-color: #2B6EAE;
}
input[type=radio]:checked+label:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 5px;
  width: 8px;
  height: 8px;
  background: #2B6EAE;
  border-radius: 100%;
}
// input 끝
textarea{
  width: 100%;
  height: 150px;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  padding: 10px;
  font-weight: 400;
  font-size: 13px;
  color: #666;
  background: #FFFFFF;
  box-sizing: border-box;
  resize: none;
}

.blind {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  width: 1px;
  height: 1px;
}
.text_left{text-align: left;}
.text_center{text-align: center;}
.text_right{text-align: right;}
.flex{
  display: flex;
}
@media (max-width: 767px){
  .m_flex{
    display: flex;
  }
}
.flex_start{
  justify-content: flex-start !important;
}
.flex_center{
  justify-content: center !important;
}
.flex_end{
  justify-content: flex-end !important;
}
.align_start{
  align-items: start;
}
.align_center{
  align-items: center;
}
.align_end{
  align-items: end;
}
.cursor_default{
  cursor: text;
}
.img_100{
  width: 100%;
}
.mt10{margin-top: 10px;}
.mr10{margin-right: 10px;}
.mb10{margin-bottom: 10px;}
.ml10{margin-left: 10px;}
.ml20{margin-left: 20px;}
.table_none_data{
  text-align: center;
  padding: 16px 20px;
  border-bottom: 1px solid #E3E3E3;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
td{
  .table_none_data{
    border: none !important;
    padding: 0px;
  }
}
.none_data{
  text-align: center;
  padding: 10px;
  border-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
@media (max-width: 767px){
  .table_none_data{
    padding: 50px 0px !important;
  }
}
.text_danger{
  color: #D23C50 !important;
}
._gray{
  margin-left: 8px;
  font-weight: 400;
  font-size: 13px;
  color: #666666;
}
.border_top{border-top: 1px solid #ddd;}
.border_right{border-right: 1px solid #ddd;}
.border_bottom{border-bottom: 1px solid #ddd;}
.border_left{border-left: 1px solid #ddd;}
@media (max-width: 767px){
  ._gray{
    margin-left: 0px;
  }
}
.text_indent{
  display: inline-block;
  text-indent: 15px;
}
// 메인 팝업 img
table td p img{
  width: 100%;
}

// 메인 빼고 다른 페이지들의 퀵메뉴
.main{
  .quick_wrap{
    right: 90px;
  }
}
.quick_wrap{
  position: fixed;
  top: 130px;
  right: 50px;
  width: 106px;
  margin-right: 0px;
  border: 1px solid #E3E1E1;
  border-radius: 20px;
  padding: 20px 5px 0px;
  background-color: #fff;
  text-align: center;
  z-index: 1;

  .quick_right{
    border: 0px;
  }
}
@media (max-width: 767px){
  .quick_wrap{
    display: none;
  }
}
.quick_right{
  border: 1px solid #EEE;
  border-radius: 7px;
  background-color: #FFF;
}
.quick_right__item{
  width: 100px;
  border-top: 1px solid #EEE;

  // &:nth-of-type(5){
  //   .quick_right__link{
  //     padding-bottom: 0px;
  //   }
  // }
}
.quick_right__link{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 20px 0px;
  color: #000;
  font-size: 14px;
  text-align: center;

  i{
    display: inline-block;
    // width: 59px;
    // height: 60px;
    margin-bottom: 5px;
  }
}
// 퀵메뉴 - 라이트바 - 아이콘
.quick_right__img01{background: url("../images/common/ic_right01.png") center top no-repeat;} // 운영연수안내
.quick_right__img02{background: url("../images/common/ic_right02.png") center top no-repeat;} // 발급신청
.quick_right__img03{background: url("../images/common/ic_right03.png") center top no-repeat;} // FAQ
.quick_right__img04{background: url("../images/common/ic_right04.png") center top no-repeat;} // 영상자료
.quick_right__img05{background: url("../images/common/ic_right05.png") center top no-repeat;} //청렴게시판
.quick_right__img06{background: url("../images/common/ic_right06.png") center top no-repeat;} // 이수자 나눔공간
.quick_right__img07{width: 42px; height: 28px; background: url("../images/common/ic_right07.svg") center top no-repeat;} // 터치교사단 신청


// 컨텐츠 크키 1560px
.header,
.container,
.footer{
  width: 1560px;
  margin: 0 auto;
}
@media (max-width: 767px){
  .header,
  .container,
  .footer{
    width: 100%;
    margin: 0 auto;
  }
}
// 컨텐츠 크키 1560px



// 스킵네비 시작
.skip_nav>a{
  position: absolute;
  top: -999px;
  left: -999px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  overflow: hidden;
  background-color: #ff0000;
  color: #ffffff;
}
.skip_nav a:focus{
  top: 0px;
  left: 0px;
  z-index: 999;
}
// 스킵네비 끝



// header - gnb 시작
.header_wrap{
  position: relative;
  border-bottom: 1px solid #dddddd;

  // &._active{
  //   .sub_gnb{
  //     display: block;
  //   } 
  
  //   &::before{
  //     content: "";
  //     position: absolute;
  //     top: 79px;
  //     left: 0px;
  //     width: 100%;
  //     height: 200px;
  //     background-color: #f8f8f8;
  //     box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.08);
  //     z-index: 100; //gnb z-index값은 100~199
  //   }
  // }
}
@media (max-width: 767px){
  .header_wrap{
    padding: 20px 10px;

    &::before{
      display: none;
    }
  }
}
.header{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 78px;
}
@media (max-width: 767px){
  .header{
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }
}
.header_logo_wrap,
.footer_logo_wrap{
  margin: 0px;
  img{
    width: 200px;
    height: 30px;
    vertical-align: top;
  }
}
@media (max-width: 767px){
  .header_logo_wrap{
    text-align: center;
  }
}
.header_logo{
  display: flex;
  align-items: center;
}
.gnb_wrap{
  display: flex;
  align-items: center;
  margin-left: 6px;
  // margin-left: 50px; // 혹시 커뮤니티에 라인을 맞출 때를 대비한 마진 값.
}
@media (max-width: 767px){
  .gnb_wrap{
    display: none;
    position: absolute;
    top: 81px;
    right: 0px;
    width: 100%;
    background-color: #f8f8f8;
    z-index: 999;

    &._active{
      display: block;
    }

    .main_gnb{
      display: initial;
    }
  }
}
.main_gnb{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 890px;
  height: 100%;

  .main_gnb_item{
    position: relative;

    &:hover, &:focus, &._active{
      .sub_gnb{
        display: block;
      }
    }

    &:first-child .sub_gnb{
      border-left: 1px solid #E3E1E1;
    }
  }

  .main_gnb__link{
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 160px;
    padding: 30px 50px;
    font-size: 17px;
    font-weight: 600;
    line-height: normal;

    &._active, &:hover, &:focus{
      color: #0059AE;
    }
  }
}
@media (max-width: 767px){
  .main_gnb_item{
    display: flex;
    border-bottom: 1px solid #E3E1E1;
		padding: 10px;

    .main_gnb__link, .sub_gnb{
      flex: 0 0 50%;
      font-size: 17px;
    }
    .main_gnb__link{
      font-weight: 600;
			padding: 10px;
      &._active, &:hover, &:focus{
        color: #0059AE;
      }
    }

    &._active{
      background-color: #ffffff;
    }
  }
}
.sns_wrap{
  margin-left: 70px;
}
.gnb_sns{
  width: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sns_item__link{
  display: flex;
}

// 서브 gnb
.sub_gnb{
  display: none;
  position: absolute;
  top: 77px;
  left: 0px;
  width: 100%;
  border: 1px solid #E3E1E1;
  border-top: none;
  background-color: #fff;

  z-index: 101; //gnb z-index값은 100~199

  &:hover, &:focus{
    display: block;
    background-color: #fff;
  }

  .sub_gnb_item{
    width: 100%;

    .sub_gnb__link{
      display: flex;
      justify-content: center;
      padding: 15px 0px;
      font-size: 15px;
      font-weight: 400;

      &._active, &:hover, &:focus{
        font-weight: 600;
        color: #0059AE;
      }
    }
  }

  &:first-child{
    border-left: 1px solid #E3E1E1;
  }
}
@media (max-width: 767px){
  .sub_gnb{
    position: static;
    display: block;
    width: 100%;
    border: none !important;
    background-color: transparent;

    &._active, &:hover, &:focus{
      background-color: transparent;
    }
  }

  .sub_gnb_item{
    &:last-child{
      margin-bottom: 0px;
    }

    .sub_gnb__link{
      display: block;
      font-size: 14px;

      &._active{
        color: #0059AE;
      }
    }
  }
}



// 아이콘 시작
.ic_home,
.ic_youtube,
.ic_facebook,
.ic_instagram,
.ic_prt,
.ic_arrow_right,
.ic_attach,
.ic_lock,
.ic_tab_down,
.ic_service01,
.ic_service02{
  display: inline-block;
  width: 14px;
  height: 13px;
  background: url(../images/common/ic_home.png) top left no-repeat;
}
.ic_youtube{
  width: 18px;
  height: 15px;
  background-image: url(../images/common/ic_yout.png);
}
.ic_facebook{
  width: 16px;
  height: 17px;
  background-image: url(../images/common/ic_face.png);
}
.ic_instagram{
  width: 16px;
  height: 17px;
  background-image: url(../images/common/ic_ins.png);
}
.ic_prt{
  width: 16px;
  height: 16px;
  background-image: url(../images/common/ic_print.png);
}
.ic_arrow_right{
  width: 5px;
  height: 8px;
  margin: 0px 10px;
  background-image: url(../images/common/ic_arrow_right.png);
}
.ic_attach{
  width: 12px;
  height: 16px;
  margin-left: 5px;
  background-image: url(../images/common/ic_attach.png);
}
.ic_lock{
  display: inline-block;
  width: 14px;
  height: 16px;
  margin-left: 5px;
  flex: 0 0 16px;
  background: url(../images/common/ic_lock.png) top left no-repeat;
}
.ic_tab_down{
  content: "";
  display: inline-block;
  width: 12px;
  height: 8px;
  background: url("../images/common/ic_tab_down.png") no-repeat;

  &.ic_tab_up{
    background: url("../images/common/ic_tab_up.png") no-repeat;
  }
}
.ic_service01{
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-image: url(../images/common/ic_service01.png);
}
.ic_service02{
  width: 36px;
  height: 34px;
  margin-right: 10px;
  background-image: url(../images/common/ic_service02.png);
}
.ic_question{
  &::before{
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background-image: url(../images/common/ic_question.png);
  }
}

// 아이콘 sprite
.ic_subway,
.ic_car,
.ic_bus,
.ic_train{
  display: inline-block;
  margin-right: 5px;

}
// 아이콘 끝



.container{
  display: flex;
  margin-top: 50px;
  min-height: calc(100% - 303px);

  &.survey_wrap{
    width: 1280px;

    .main_content{
      display: flex;
      flex-flow: column;
      margin-bottom: 50px;
    }

    .border_box{
      height: initial;
    }
  }
}
@media (max-width: 767px){
  .container{
    display: block;
    margin-top: 0px;
    padding: 0px 10px;
  }
}
.content_wrap{

}
.content{
  display: flex;
}



// 왼쪽 사이드바 시작
.lnb_wrap{
  height: 100%;
  margin-right: 40px;
  border-radius: 20px;
  background-color: #F2F4F8;
  box-shadow: 2px 2px 8px rgba(15, 56, 95, 0.16);

  .lnb_title{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 240px;
    height: 112px;
    margin: 0px;
    border-radius: 20px 20px 0px 0px;
    padding-left: 20px;
    font-family: 'NanumGothic';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(180deg, #023C73 0%, #2B6EAE 96.87%);

    &::before{
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      display: block;
      background-image: url(../images/common/lnb_bg.png);
      background-repeat: no-repeat;
      background-position: bottom right;
      width: 100%;
      height: 112px;
    }
  }

  .lnb_menu{
    padding: 35px 20px;

    .lnb_menu__link{
      display: flex;
      width: 100%;
      margin-bottom: 10px;
      padding: 8px 20px;
      align-items: center;
      font-family: 'NanumGothic';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: normal;
      color: #767676;

      &._active{
        border-radius: 30px;
        font-weight: 600;
        background-color: #5399DA;
        color: #ffffff;
      }
      &:last-child{
        margin-bottom: 0px;
      }
    }
  }
}
// 왼쪽 사이드바 끝


// 컨텐츠 헤더 시작
.main_content_header{
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px 25px;
}
@media (max-width: 767px){
  .main_content_header{
    justify-content: flex-start;
    flex-flow: column;
    align-items: initial;
    margin-top: 12px;
  }
}
.title_info_wrap{
  display: flex;
  align-items: center;
}
@media (max-width: 767px){
  .title_info_wrap{
    justify-content: flex-end;
  }
}
.main_title{
  margin: 0px;
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: #111111;
}
@media (max-width: 767px){
  .main_title{
    margin-top: 20px;
  }
}
.breadcrumb{
  display: flex;
  justify-content: space-between;
  align-items: center;

  .breadcrumb_item{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'NanumGothic';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #767676;
  }
}
.prt_download{
  display: flex;
  margin-left: 10px;
  border-radius: 8px;
  padding: 4px;
  background: #f5f5f5;
}
// 컨텐츠 헤더 끝


// 메인 컨텐츠 영역 시작
.main_content{
  width: 1280px;
  font-size: 16px;
  font-weight: normal;
  color: #666;


  &._ft_content{
    width: 1560px;
  }
}
@media (max-width: 767px){
  .main_content{
    width: 100%;

    &._ft_content{
      width: 100%;
    }
  }
}
.border_box{
  height: 100%;
  border: 1px solid #dddddd;
  border-radius: 20px;
  padding: 20px 30px;

  &.service_box{
    padding: 90px 20px 130px;
  }
}
@media (max-width: 767px){
  .border_box{
    padding: 10px;

    &.service_box{
      padding: 10px;
    }
  }
}
.detail_box{
  padding: 30px;
}
@media (max-width: 767px){
  .detail_box{
    padding: 10px 15px;
  }
}

.content_header_wrap{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 12px;
  &.training_plan_header{
    justify-content: flex-start;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px){
  .content_header_wrap{
    display: block;
  }
}
.training_plan_txt{
  .sub_title{
    margin: 0px;
    padding: 25px 0px 20px;
  }
  .sub_title__danger{
    margin: 0px;
  }
}
// .content_body_wrap{
//   min-height: 300px;
// }

// 인사말 시작
.introduce_wrap{
  color: #000;
}
.introduce_txt__box{
  line-height: 2;
  font-weight: 400;
  margin-bottom: 20px;

  p{
    margin: 0px;
  }

  &:last-child{
    margin-bottom: 0px;
  }
}
.introduce_img__box{
  margin-bottom: 20px;
}
// 인사말 끝

// 찾아오시는 길 시작
.map_img__box{
  padding: 20px 0px 30px;
  text-align: center;
  .map_img{
    width: 800px;
  }
}
@media (max-width: 767px){
  .map_img__box .map_img{
    width: 100%;
  }
}
.map_explanation_wrap{
  padding-top: 20px;
  border-top: 1px solid #ddd;
  line-height: 1.45;
  font-weight: 600;
}
.map_title{
  color: #666;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin: 20px 0px;
}
.map_txt{
  margin: 0px;
}
.map_txt__item{
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}
.map__txt{
  display: inline-block;
  min-width: 80px;
}
@media (max-width: 767px){
  .map_txt__item{
    align-items: flex-start;
  }
  .map_txt{
    flex: 0 0 90%;
  }
  .map__txt{
    min-width: 60px;
  }
}
// 찾아오시는 길 끝



// 검색창 시작
.form_search_wrap{
  display: flex;
}
@media (max-width: 767px){
  .form_search_wrap{
    margin-top: 10px;
    justify-content: flex-end; //잠깐 넣어놓은거.
  }

  .form_search_wrap.m_block{
    display: block;

    .flex_box1{
      .form_search__select{
        flex: 1 1 33%;
        margin-bottom: 10px;
        &:last-child{margin-right: 0px;}
      }
    }
    .flex_box2{
      .form_search__select{
        flex: 1 1 33%;
      }
      .form_search{
        flex: 1 1 67%;
      }
    }
  }
}
.form_search__select{
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 120px;
  height: 36px;
  margin-right: 8px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  background: url(../images/common/ic_arrow_down.png) #fff no-repeat right 17px top 50%;
  background-color: #ffffff;

  &.form_search__select__year{
    width: 80px;
  }
  &.form_search__select__object{
    width: 150px;
  }
}
@media (max-width: 767px){
  .form_search__select{
    margin-right: 4px;
  }

  .form_search__select.m_on{
    margin-bottom: 10px;
  }
}
.form_search{
  position: relative;
}
.form_search__input{
  width: 292px;
  height: 36px;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  padding: 10px 35px 10px 10px;
  font-weight: 400;
  font-size: 12px;
}
@media (max-width: 767px){
  .form_search__input{
    width: 100%;
  }
}
.form_search__btn{
  position: absolute;
  top: 50%;
  right: 5px;
  width: 30px;
  height: 30px;
  background: url(../images/common/ic_view.png) no-repeat center;
  transform: translateY(-50%);
}
// 검색창 끝

.form__select{
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 120px;
  height: 36px;
  margin-right: 8px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 12px;
  background: url(../images/common/ic_arrow_down_black.png) #fff no-repeat right 8px top 50%;
  background-color: #ffffff;
}

// 달력 시작
.form_date{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 0px;
    left: 124px;
    right: 0px;
    width: 36px;
    height: 36px;
    border: 1px solid #DDDDDD;
    border-radius: 0px 6px 6px 0px;
    background-color: #ccc;
    box-sizing: border-box;
    background: url(../images/common/ic_date.png) no-repeat center;
    background-color: #F5F5F5;
    z-index: -1;
  }
}
.detail_table__item{
  &.detail_table__item__date{
    width: 160px;
    color: #333333;
    background-color: transparent;
  }
}
// 달력 끝

// 리스트 탭 시작
.training_tab__list{
  display: flex;
  flex-flow: wrap;
  margin: 14px 0px 30px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #555555;

  li:first-child{
    .training_tab__link{
      border-left: 1px solid #E3E1E1;
      border-radius: 8px 0px 0px 8px;
    }
  }
  li:last-child{
    .training_tab__link{
      border-right: 1px solid #E3E1E1;
      border-radius: 0px 8px 8px 0px;
    }
  }


  .training_tab__link{
    display: inline-block;
    padding: 13px 20px;
    border: 1px solid #E3E1E1;
    border-right: none;
    background: #F5F5F5;
    font-family: 'NanumGothic';
    font-weight: 400;
    line-height: normal;

    &._active{
      font-size: 13px;
      font-weight: 400;
      background: #FFFFFF;
    }
  }
}
// 리스트 탭 - 상세 페이지 시작
.training_tab__panels{
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.training_tab__panel{
  display: none;

  &._open{
    display: block;
  }
}
.application_wrap{
  display: flex;
  flex-wrap: wrap;
}
.application_box{
  width: 290px;
  box-sizing: border-box;
  margin: 0px 19px 20px 0px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #FFFFFF;

  &:nth-child(4n){
    margin-right: 0px;
  }
}
@media (max-width: 767px){
  .application_box{
    width: 100%;
    margin-right: 0px;
  }
}
.application_box__thumbnail{
  .img__thum{
    border-radius: 8px 8px 0px 0px;
  }
}
.application_box__content{
  border-top: 1px solid #ddd;
  padding: 12px 20px 16px;

  .application_box__subtitle{
    margin-bottom: 4px;
    font-size: 13px;

    .application_box__branch{
      position: relative;
      margin-right: 5px;

      &::after{
        content: "|";
        display: block;
        position: absolute;
        top: 1px;
        left: initial;
        right: -5px;
      }
    }
  }
  .application_box__title{
    margin: 0px;
    font-weight: 600;
    font-size: 16px;
    color: #111111;
  }
  .application_box__time{
    margin: 16px 0px 20px;
  }
}
.application_box__btn{
  margin-top: 20px;
  text-align: center;
}
// 리스트 탭 - 상세 페이지 끝

// FAQ 탭
.faq__wrap{}
.tab__link{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 8px;
  padding: 18px 30px;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  background-color: #F5F5F5;
}
.tab__panel{
  display: none;
  margin: -6px 0px 10px;
  border: 1px solid #E3E3E3;
  padding: 40px 30px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #000000;

  .tab__panel_link{
    color: #0059AE;
    text-decoration: underline;
  }

  &._open{
    display: block;
  }
}


// 주요연수 시작
.training_intro_wrap{
  display: flex;
  flex-wrap: wrap;
}
.training_intro_box{
  width: 392px;
  box-sizing: border-box;
  margin: 0px 19px 20px 0px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px 20px 16px;
  background: #F5F5F5;

  &:nth-child(3n){
    margin-right: 0px;
  }
}
@media (max-width: 767px){
  .training_intro_box{
    width: 100%;
    margin-right: 0px;
  }
}
.training_intro_box__thumbnail{
  width: 100%;
  height: 155px;
  &.training_intro_box__thumbnail_edunet{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #fff;

    .img__thum_edunet{
      width: 156px;
    }
  }


  .img__thum{
    width: 100%;
    border-radius: 8px;
  }
}
.training_intro_box__title{
  font-weight: 600;
  font-size: 16px;
  color: #333333;
	margin: 20px 0px;
}
.training_intro_box__txt{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  p{
    margin: 0px;
    letter-spacing: -0.4px;
  }
}
// 주요연수 시작
// 리스트 탭 끝



// 서브타이틀 - h3, 서브타이틀-경고문
.sub_title_wrap{
  flex: 0 0 65%;
  .btn__guide{
    margin-left: 10px;
  }
}
.sub_title{
  margin: 0px;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #555555;
}
.sub_title__danger{
  margin: 0px;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 12px;
  color: #777777;
}


// 테이블 스타일 시작
.list_table{
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #000000;

  thead{
    border-radius: 8px;
    background: #DDE2EC;
    tr{
      th{
        padding: 12px 10px;
        font-weight: 400;
        background-color: #DDE2EC;
        text-align: center;

        &:first-child{
          border-radius: 8px 0px 0px 8px;
          padding-left: 20px;
        }
        &:last-child{
          border-radius: 0px 8px 8px 0px;
        }
      }

      &:first-child{
        border-radius: 8px;
      }
    }
  }

  tbody{
    tr{
      border-bottom: 1px solid #E3E3E3;
      th{
        padding: 12px 10px 12px 20px;
        text-align: center;
        font-weight: 400;
      }

      td{
        padding: 16px 12px;
        text-align: center;
				vertical-align: middle;

        &:first-child{
          padding-left: 20px;
        }

        .list_table__title{
          display: flex;
          align-items: center;
          line-height: normal;
					text-align: left;
					color: #333;
        }
      }
    }
  }

  .btn__application{
    width: 80px;
    height: 30px;
    font-size: 12px;
  }


  &._close{
    display: none;
  }


	.detail__circle{
		line-height: 1.45;
	}
}
.list_table__process{
  display: inline-block;
  width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  /* 말줄임 적용 */
  line-height: normal;
}
.list_table__border{
  tbody{
    td{
      border-left: 1px solid #ddd;

      &:first-child{border-left: none;}
    }
  }
}




// 리스트 상세 페이지 안의 테이블
.detail_top_table_group{
  display: flex;
  align-items: flex-start;
  margin: 20px 0px 30px;

  @media (max-width: 767px){
    display: block;
  }
}
.detail_img_wrap{
	flex: 0 0 424px;
  margin-right: 50px;

  @media (max-width: 767px){
    margin-right: 0px;
  }

  img{
    max-width: 424px;
    width: 100%;
    height: 240px;
    @media (max-width: 767px){
      width: 100%;
      height: 100%;
    }
  }

  &+.detail_table_wrap{
    flex: 1 1 auto;
  }
}
.detail_table_wrap{
  &+.detail_table_wrap{
    margin-top: 60px;
  }
}
@media (max-width: 767px){
  .detail_table_wrap{
    margin: 20px 0px;
    &+.detail_table_wrap{
      margin-top: 30px;
    }
  }
}
.compulsory_text{
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #111111
}
.detail_table{
  display: flex;
  border-top: 1px solid #E3E3E3;
  border-bottom: 1px solid #E3E3E3;
  font-weight: 400;
  font-size: 13px;
  color: #666666;
  
  &+.detail_table{
    border-top: none;
  }
}
@media (max-width: 767px){
  .detail_table{
    display: block !important;

    .detail_table_wrap{
      display: flex;
      align-items: center;
      .detail_table__hd{
        flex: 0 0 25%;
        padding: 10px;
        text-align: center;
      }
      .detail_table__content{
        flex: 0 0 75%;
        padding: 10px;
      }
    }
  }
}
.detail_table_container{
  display: flex;
  align-items: center;
}
@media (max-width: 767px){
  .detail_table_container{
    display: block;
    border-top: 1px solid #E3E3E3;
    align-items: initial;

    &:first-child{
      border-top: none;
    }
  }
}
.detail_table__hd{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 30%;
  padding: 22px 10px;

  font-weight: 400;
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: normal;

  @media (max-width: 767px){
    padding: 10px;
  }
}
.detail_table__content{
  flex: 0 0 70%;
  padding: 10px;
}
@media (max-width: 767px){
  .detail_table__content{
    padding: 10px;
  }
}
.t_felx100{
  flex: 0 0 100%;
  .detail_table__hd{
    flex: 0 0 10%;
  }
  .detail_table__content{
    flex: 0 0 90%;
  }
}
.t_felx70{
  flex: 0 0 66%;
  .detail_table__hd{
    flex: 0 0 15%;
  }
  .detail_table__content{
    flex: 0 0 85%;
  }
}
.t_felx50{
  flex: 0 0 50%;
}
.t_felx30{
  flex: 0 0 33%;
  .detail_table__hd{
    flex: 0 0 30%;
  }
  .detail_table__content{
    flex: 0 0 70%;
  }
}
@media (max-width: 767px){
  .t_felx100,
  .t_felx50,
  .t_felx30{
    .detail_table__hd{
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 30%;
    }
    .detail_table__content{
      display: flex;
      align-items: center;
      flex: 0 0 70%;
    }
  }
}
.detail_table__box{
  display: flex;
  align-items: center;
  margin-left: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #111111;
  align-items: center;

  .detail_table__item{
    margin-right: 4px;

  }
}
// 테이블 회색
.detail_table_wrap.t_gray{
  .detail_table__hd{
    height: 100%;
    background-color: #F5F5F5;
  }
}
// 테이블 하얀색
.detail_table_wrap.t_white{
  font-weight: 400;
  font-size: 13px;
  color: #111111;

  .detail_table_container{
    align-items: initial;
  }

  .detail_table__hd{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    background-color: #FFFFFF;
    color: #111111;

    .detail_table__title{
      text-align: center;
    }
  }
  .detail_table_container.t_felx50+.t_felx50,
  .detail_table_container.t_felx30+.t_felx30{
    .detail_table__hd{
      border-left: 1px solid #E3E3E3;
    }
  }

  .detail_table{
    border-left: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
  }
  .detail_table__content{
    border-left: 1px solid #E3E3E3;
    padding: 8px 20px;

    .form_search{
      display: flex;
      align-items: end;
      ._gray{
        margin-left: 40px;
      }
    }

    .form__select{
      width: 240px;
    }
    .form_search__input{
      width: 205px;
      border-radius: 6px 0px 0px 6px;
      padding: 10px;
    }
    .form_search__btn{
      top: 0px;
      right: 204px;
      width: 36px;
      height: 36px;
      border: 1px solid #DDDDDD;
      border-radius: 0 8px 8px 0px;
      background-color: #F5F5F5;
      transform: initial;
    }
    .detail_table_radiobox{
      display: flex;
      justify-content: flex-start;
      margin-top: 0px;
      .flex+.flex{
        margin-left: 0px;
      }
    }
    .detail__hyphen::before{
      margin: 0px 5px;
    }

    .btn_wrap{
      margin-top: 0px;
      margin-left: 8px;
    }
  }

  .detail_table__item{
    &:first-child.detail_table__item__tel{
      width: 50px;
    }
    &.detail_table__item__tel{
      width: 60px;
    }
  }
}
@media (max-width: 767px){
  .detail_table_wrap.t_white{
    .detail_table_container.t_felx50+.t_felx50,
    .detail_table_container.t_felx30+.t_felx30{
      .detail_table__hd{
        border-left: none;
      }
    }
    .detail_table__content{
      flex-flow: wrap;

      .flex{
        flex-wrap: wrap;
      }
      .form__select{
        width: 100%;
      }
      .form_date{
        width: 100%;

        &::before{
          left: initial;
        }
      }
      .detail_table__item{
          &.detail_table__item__date{
          width: 100%;
        }
      }
      .form_search{
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        ._gray{
          margin-top: 5px;
          margin-left: 0px;
        }
      }
      .form_search__input{
        width: 100%;
        border-radius: 8px;
      }
      .form_search__btn{
        right: 0px;
        left: initial;
      }
    }
    .detail_table__box{
      display: block;
      margin-top: 5px;
      margin-left: 0px;
    }
  }
}

// 개인정보수집 테이블
.detail_table_wrap.t_info{
  .detail_table_header{
    .t_felx100{
      .detail_table__content{
        flex: 0 0 100%;
        border-left: none;
      }
    }
  }

  .t_felx100{
    .detail_table__hd{
      flex: 0 0 22%;
    }
    .detail_table__content{
      flex: 0 0 78%;
    }
  }


  .detail_table{
    border-left: 1px solid #E3E3E3;
    border-right: 1px solid #E3E3E3;
  }
  .detail_table__content{
    border-left: 1px solid #E3E3E3;
  }
}
.detail_table_container{
  align-items: center;
}
.detail_table_radiobox{
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .flex+.flex{
    margin-left: 35px;
  }
}
.detail_table__content{
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #666666;

  p{
    margin: 0px;
    margin-bottom: 10px;

    &:last-child{
      margin-bottom: 0px;
    }
  }
}
.form__radio{
  margin-right: 5px;
}
.form__check{
  margin-right: 5px;
}
.detail_table_title{
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 8px;
  padding-left: 8px;
  font-weight: 600;
  font-size: 15px;
  color: #333333;

  &::before{
    content: "";
    position: absolute;
    top: 2px;
    left: 0px;
    width: 2px;
    height: 14px;
    background-color: #C3C3C3;
  }

  .compulsory_text{
    margin-bottom: 0px;
  }
}
.detail_table_radiobox+.detail_table_title,
.survey_border2+.detail_table_title,
.detail_sec_wrap+.detail_table_title,
.detail_sec_wrap{
  margin-top: 60px;
}
.form_checkbox_wrap{
  position: relative;
  .detail_table_checkbox{
    position: absolute;
    top: calc(50% + 15px);
    right: 30px;
    display: flex;
    align-items: center;
    transform: translateY(-50%);

    .detail_table_check{
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      color: #111111;

      label{
        min-width: 30px;
      }

      .form__check{
        margin-right: 4px;
      }
    }

    .application_box__btn{
      margin-top: 0px;
      margin-left: 20px;
    }
  }
}
@media (max-width: 767px){
  .detail_table_wrap.t_info{
    .detail_table_container{
      align-items: center;
    }
    .detail_table__hd{
      padding: 5px;
      text-align: center;
    }
  }
  .form_checkbox_wrap{
    margin-bottom: 60px;

    .detail_table_checkbox{
      top: initial;
      right: initial;
      left: 50%;
      bottom: -50px;
      transform: translateX(-50%);

      .btn__application{
        width: 96px;
      }
    }
  }
}
// 리스트 상세 페이지 안의 테이블
.fixed_writing_wrap{
  .fixed_writing{
    .list_table__link{
      color: #023C73;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
    }
  }
}

// 공지사항 - 고정글
.fixed_icon_wrap{
  display: flex;
  align-items: center;
  justify-content: center;
}
// 테이블 스타일 끝


// 페이지네이션 시작
.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
@media (max-width: 767px){
  .pagination{
    justify-content: center;
  }
}
.page_item:last-child{
  .page_link{
    border-right: 1px solid #E3E1E1;
  }
}
.page_link{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #E3E1E1;
  border-right: none;
  padding: 10px;
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  background-color: #F5F5F5;
  color: #BBBBBB;

  &._active{
    color: #555555;
    background-color: #ffffff;
  }
}

// 페이지네이션 아이콘
.page_link__first,
.page_link__last{
  background: url(../images/common/ic_first.png) #F5F5F5 no-repeat center;
  border-radius: 8px 0px 0px 8px;
}
.page_link__last{
  background-image: url(../images/common/ic_last.png);
  border-radius: 0px 8px 8px 0px;
}
.page_link__prev,
.page_link__next{
  background: url(../images/common/ic_prev.png) #F5F5F5 no-repeat center;
}
.page_link__next{
  background-image: url(../images/common/ic_next.png);
}
// 페이지네이션 끝



// 리스트 - 상세페이지 시작
.detail_header_wrap{
  .table_detail{
    margin: 20px 0px 30px;
  }
}
.detail_header{
  margin-bottom: 30px;
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 30px;
}
.detail_title{
  display: flex;
  align-items: center;
  margin: 0px;
  font-weight: 600;
  font-size: 16px;
  color: #111;

  &.answer_title{
    margin-top: 20px;
  }

  .noti__item_label{
    margin-left: 12px;

    &::before{
      padding: 6px;
    }
  }

  @media (max-width: 767px){
    display: block;
  }

}
.detail_title__lebel{
  margin-right: 10px;
  border: 1px solid #0059AE;
  border-radius: 8px;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 14px;
  color: #0059AE;
  text-align: center;
}
@media (max-width: 767px){
  .detail_title__lebel{
    min-width: 80px;
  }
}

.detail_body{
  min-height: 300px;
}
.detail_body__text{
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;

  h4{
    margin: 0px 0px 10px;
  }


  p{
    margin: 0px 0px 20px;
  }
  ol{
    margin: 5px 0px 20px;
    padding-left: 25px;

    li{
      list-style: decimal;
    }
  }

  img{
    width: 100%;
  }
}

.detail__text_small{
  font-size: 12px;

  &.text_indent{
    margin-bottom: 8px;
  }
}

// 리스트 상세페이지의 특수문자
.detail__hyphen::before{
  content: "-";
  margin-right: 5px;
}
.detail__circle::before{
  content: "•";
}
.detail__danger::before{
  content: "※";
  margin: 0px 5px;
}
.detail__asterisk::before{
  content: "*";
  display: inline-block;
  margin-top: -5px;
  font-weight: 400;
  font-size: 13px;
  color: #E00000;
  vertical-align: top;
}
.detail__triangle::before{
  content: "▶";
  margin-right: 5px;
  font-weight: 400;
  font-size: 13px;
  color: #666666;
}
// 리스트 상세페이지의 특수문자

.list_text__link{
  color: #0064C4;
  text-decoration: underline;
}


.detail_footer{
  margin-top: 30px;
  border-top: 1px solid #E3E3E3;
  padding-top: 30px;
}
.detail_attachment__text{
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 600;
  color: #111111;
  line-height: normal;
}
.detail_attachment{
  margin-bottom: 20px;
}
.detail_attachment:last-child{
  margin-bottom: 0px;
}
.detail_attachment_wrap{
  margin-left: 10px;
}
.list_table__link+.detail_attachment_wrap{
  margin-top: 16px;
}
.detail_attachment__link{
  font-size: 14px;
  text-decoration-line: underline;
  color: #0064C4;
  margin-right: 5px;

  &.privacy_detail_attachment__link{
    display: inline-flex;
    align-items: center;
    margin-right: 15px;

    .ic_attach{
      margin-right: 5px;
      margin-left: 0px;
    }
  }
}
.detail_attachment__memory{
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.detail_attachment__num{
	display: inline-flex;
	align-items: center;

	color: #666;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: -0.28px;

	.ic_attach{
		margin-left: 0px;
		margin-right: 5px;
	}

	@media (max-width: 767px){
		font-size: 12px;
	}
}
// 문의하기 상세 하단
.detail_answer__text{
  font-size: 14px;
  font-weight: 400;
  color: #666666;

  p{
    margin: 0px;
    margin-bottom: 5px;

    &:last-child{margin-bottom: 0px;}
  }
}
.security_wrap{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.security__img{

}
.security__input{
  position: relative;
  margin-left: 4px;

  &::before{
    content: "";
    position: absolute;
    top: 10px;
    left: 14px;
    width: 10px;
    height: 10px;
    background: url("../images/common/ic_refresh.png") no-repeat center;
  }
}
.security__btn{
  flex: 1 1 100%;
  margin-top: 14px;
}

// 상세 탭 시작
.detail_tab__list{
  display: flex;
  flex-flow: wrap;
  margin: 14px 0px 30px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #555555;

  li:first-child{
    .detail_tab__link{
      border-top: 1px solid #E3E1E1;
      border-left: 1px solid #E3E1E1;
      border-radius: 8px 0px 0px 8px;

      @media (max-width: 767px){
        border-radius: 0px;
      }
    }
  }
  li:last-child{
    .detail_tab__link{
      border-right: 1px solid #E3E1E1;
      border-radius: 0px 8px 8px 0px;

      @media (max-width: 767px){
        border-radius: 0px;
      }
    }
  }


  .detail_tab__link{
    display: block;
    padding: 13px 20px;
    border: 1px solid #E3E1E1;
    border-right: none;
    background: #F5F5F5;
    font-family: 'NanumGothic';
    font-weight: 400;
    line-height: normal;

    &._active{
      font-size: 13px;
      background: #FFFFFF;
    }

    @media (max-width: 767px){
      border-top: none;
      border-right: 1px solid #E3E1E1;
      padding: 5px 20px;
    }
  }

  @media (max-width: 767px){
    display: block;
  }
}
// .detail_tab__list{
//   display: flex;
//   flex-flow: wrap;
//   margin-bottom: 30px;
//   font-size: 13px;
//   font-weight: 400;
//   color: #BBBBBB;

//   &:first-child{
//     border-left: 1px solid #BBBBBB;
//   }

//   li{
//     border-right: 1px solid #BBBBBB;
//   }
//   .detail_tab__link{
//     padding: 10px 30px;

//     &._active{
//       font-size: 13px;
//       font-weight: 600;
//       color: #0059AE;
//     }
//   }
// }
.detail_tab__panels{
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: #666666;
}
@media (max-width: 767px){
  .detail_tab__panels{
    margin-left: 0px;
  }
}
.detail_tab__panel{
  display: none;

  &._open{
    display: block;
  }

  p{
    margin: 0 0 20px;
  }
  img{
    width: 100%;
  }
}
// 상세 탭 끝
// 리스트 - 상세페이지 끝

// 영상 시작
.video_wrap{
  margin-top: 30px;
}
.video_box{
  display: flex;
  flex-wrap: wrap;
}
.video__item{
  flex: 1 1 30%;
  margin-right: 50px;
  margin-bottom: 50px;

  &:nth-child(3n){
    margin-right: 0px;
  }
}
.video_thum{
  height: 210px; // 이미지 없음의 height값으로 맞춰놓은 것.
  margin-bottom: 5px;
  border: 1px solid #000;
}
.video__title{
  margin: 0px;
  font-weight: 600;
  font-size: 16px;
  color: #111111;
}
@media (max-width: 767px){
  .video__item{
    flex: 0 0 100%;
    margin-right: 0px;
  }
}

.detail_video_wrap{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.video{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
}
// 영상 끝

// 시스템오류 관련 문의 시작
.service_inquire_wrap{
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px){
  .service_inquire_wrap{
    display: block;
  }
}
.service_bg{
  &::before{
    content: "";
    display: block;
    width: 400px;
    height: 298px;
    background: url(../images/page/service/service_img01.png) no-repeat center;
  }
}
@media (max-width: 767px){
  .service_bg{
    &::before{
      width: 100%;
    }
  }
}
.service_ask_wrap{
  margin-left: 110px;
}
@media (max-width: 767px){
  .service_ask_wrap{
    margin: 10px 0px 0px;
  }
}
.service_ask{
  width: 360px;
  border-radius: 12px;
  padding: 40px;
  background-color: #F5F5F5;

  &+.service_ask{
    margin-top: 40px;
  }

  .service__text{
    margin-top: 25px;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
}
@media (max-width: 767px){
  .service_ask{
    width: 100%;
    padding: 20px;

    .flex{
      display: block;
    }
    .service_subtitle{
      text-align: left;
    }
    .service_subtitle2{
      margin-left: 0px;
    }
    .service__text{
      margin-top: 10px;
    }
    .btn__service{
      margin: 0px;
    }

    &+.service_ask{
      margin-top: 20px;
    }
  }
}
.service_title{
  margin: 20px 0px 25px;
  line-height: 34px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: #111111;
}
@media (max-width: 767px){
  .service_title{
    font-size: 18px;
    line-height: 22px;
  }
}
.service_subtitle{
  margin: 0px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: #111111;
}
.service_subtitle2{
  margin: 0px;
  margin-left: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #111111;
}
@media (max-width: 767px){
  .service_subtitle{
    font-size: 18px;
  }
}
.text_blue{
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  color: #0059AE;
}
.service__text{
  font-weight: 600;
  font-size: 16px;
  color: #111111;
}
.service__text_small{
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
// 시스템오류 관련 문의 끝

// 조직도 시작
.organization_wrap{
  margin-top: 20px;
}
.organ_title_wrap{
  width: 420px;
  margin: 0px auto 40px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  background-color: #7890C1;
}
.organ_title{
  line-height: 24px;
  padding: 20px 0px;
}
.organ_subtitle{
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  border: 2px solid #7890C1;
  border-radius: 0px 0px 8px 8px;
  padding: 15px 0px;
  color: #4365A7;
  background-color: #ffffff;
}
@media (max-width: 767px){
  .organ_title_wrap{
    width: 100%;
  }
}
.organization_wrap{
  .table_wrap{
    width: 950px;
    margin: 0 auto;
    position: relative;

    &::before{
      content: "";
      position: absolute;
      top: -40px;
      left: 50%;
      width: 2px;
      height: 40px;
      background: #7890C1;
      z-index: -1;  
    }
  }
}
@media (max-width: 767px){
  .organization_wrap{
    .table_wrap{
      width: 100%;
    }
  }
}
// 조직도 끝



// button
.btn_wrap{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
}
.btn{
  width: 96px;
  height: 40px;
}

// 버튼 리스트 시작 
.btn__list{
  border: 1px solid #6E8295;
  border-radius: 8px;
  background: #FFFFFF;
}
.btn__identify{
  width: 80px;
  height: 30px;
  border-radius: 8px;
  padding: 0px;
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
  background: #0059AE;
  cursor: pointer;
}
.btn__application{
  width: 180px;
  height: 40px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  background: #0059AE;
}
.btn__ask{
  width: 120px;
  height: 40px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  background: #0059AE;
}
.btn__cencel{
  width: 120px;
  height: 40px;
  margin-left: 10px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #666;
  background: #DDDDDD;
}
.btn__choice{
  margin-left: 5px;
  padding: 4px 10px;
  background-color: #7D838E;
  color: #ffffff;
  font-size: 13px;
}
.btn__identification{
  width: 100px;
  height: 26px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  background: #969696;
}
.btn__deadline{
  width: 180px;
  height: 40px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  background: #969696;
  cursor: not-allowed;
}
.btn__shortcut,
.btn__guide{
  width: 80px;
  height: 26px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
  background: #5399DA;
  line-height: normal;

  &.btn__privay{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: initial;
    padding: 10px;
  }
}
.btn__shortcut{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.btn__service{
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  height: 32px;
  margin: 0px;
  margin-left: 24px;
  border-radius: 6px;
  padding: 9px;
  font-weight: 400;
  font-size: 12px;
  background-color: #767676;
  color: #FFFFFF;

  &::before{
    content: "";
    display: inline-block;
    width: 16px;
    height: 13px;
    margin-right: 6px;
    background: url("../images/common/ic_email.png");
  }
}
// 새로고침 버튼은 input으로 쓰고 있기 때문에 before로 따로 선언함. _ref로 검색
.btn__refresh{
  width: 86px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0px 15px 0px 26px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #666666;
  background-color: #fff;
  cursor: pointer;
}
// 버튼 리스트 끝

// 메인 컨텐츠 영역 끝




// 푸터 시작
.footer_wrap{
  margin-top: 50px;
  border-top: 1px solid #dddddd;
}
@media (max-width: 767px){
  .footer_wrap{
    margin-top: 30px;
  }
}
.footer{
  display: flex;
  justify-content: space-between;
  padding: 25px 0px;
}
@media (max-width: 767px){
  .footer{
    display: block;
    padding: 12px 10px 50px;
  }
}
.footer_logo{
  flex: 0 0 auto;
}
.footer_flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 1280px;
}
@media (max-width: 767px){
  .footer_flex{
    display: block;
  }
}
.fnb{
  display: flex;
  .fnb_item:first-child{
    .fnb_menu__link{
      padding-left: 0px;
      &::before{
        display: none;
      }
      &.link_blue{
        color: #0059AE;
      }
    }
  }
}
@media (max-width: 767px){
  .fnb{
    flex-flow: wrap;
		margin-top: 4px;

		.fnb_item{
			margin-top: 12px;
		}
  }
}
.fnb_menu__link{
  padding-left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #767676;
  &::before{
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #C4C4C4;
    margin-right: 10px;
  }
}
@media (max-width: 767px){
  .fnb_menu__link{
    display: inline-flex;
    font-size: 13px;
		padding-left: 8px;

		&::before{
			margin-right: 8px;
		}
  }
}
.adr_wrap{
  margin-top: 16px;
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #767676;
  .adr_copyright{
    display: block;
  }
}
@media (max-width: 767px){
  .adr_wrap{
    margin-bottom: 20px;
    font-size: 13px;
  }
}


// 푸터 셀렉트 박스 시작
.footer_relation_wrap{
  height: 40px;
  font-family: 'NanumGothic';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #767676;
}
@media (max-width: 767px){
  .footer_relation_wrap{
    display: flex;
    justify-content: end;
  }
}
.footer_relation__select{
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 240px;
  height: 40px;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 15px;
  background: url("../images/common/ic_ft_arrow_down.png") #fff no-repeat right 17px top 50%;
  background-color: #F2F4F8;
  color: #767676;
}
.footer_relation__btn{
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #7D838E;
  color: #ffffff;
}
// 푸터 셀렉트 박스 끝

// 푸터 끝


// 팝업 - popup 시작
.popup_wrap{
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);

  &._active{
    display: flex;
  }
}
.pop_container{
  flex: 0 0 auto;
  min-width: 350px;
  border-radius: 8px;
  padding: 0 0 20px;
  overflow-y: auto;
  background-color: #FFFFFF;
}
.pop_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #fff;
  background-color: #023C73;
}
.pop_title{
  padding-left: 20px;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
}
.pop_close{
  width: 14px;
  height: 14px;
  padding: 20px;
  background: url(../images/common/ic_close_white.png) no-repeat transparent center;
}
.pop_content{
  padding: 0px 20px;

  .pop_content__hd{
    padding: 10px 0px 4px;
    background-color: #FFFFFF;
    color: #111111;
  }
  .pop_content__box{
    display: flex;
    align-items: center;
  }

  h5{
    margin-bottom: 10px;
    font-size: 20px;
    color: #111;
  }
  ul{
    p{
      margin: 20px 0px 0px;

      &+p{margin-top: 0px;}
    }
  }
  p{
    span{
      display: block;
    }
  }


  // 팝업 안의 input
  .form_search{
    display: flex;
    align-items: center;
  }
  .form_search__input{
    width: 100%;
    border-radius: 8px 0px 0px 8px;
    border-right: none;
  }
  .form_search__btn{
    position: static;
    transform: none;
    width: 36px;
    height: 36px;
    border: 1px solid #DDDDDD;
    border-radius: 0 8px 8px 0px;
    background-color: #ffffff;
  }

  .table_wrap{
    display: block !important;
  }
  // 팝업 안의 table
  .list_table thead tr th,
  .list_table tbody tr td{
    text-align: center;
  }
}
@media (max-width: 767px){
  .pop_content{
    padding: 0px 10px;
  }
}
.pop_border{
  width: 100%;
  margin: 10px 0px;
  border: 1px solid #E3E3E3;
  padding: 10px;
  font-weight: 400;
  font-size: 13px;
  color: #666666;

  &.pop_border_pd{
    padding: 20px;
  }

  img{
    display: flex;
    margin: 0px auto;
  }
}
.pop_content__title{
  font-weight: 400;
  font-size: 13px;
  color: #111111;
}
.pop_content__box{
  .form__input{
    width: 100%;
  }
}
.pop_footer{
  margin-top: 20px;
}
.pop_ft_btn_wrap{
  display: flex;
  justify-content: center;

  .btn__application{
    width: 96px;
    height: 40px;
    margin-right: 8px;
    background-color: #0059AE;
  }
  .pop_close{
    width: 96px;
    height: 40px;
    border-radius: 8px;
    padding: 0px;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    background: #666666;
  }
}
.complete_reqIntro_img05{ }


// 메인에 띄울 팝업용
.pop_img_wrap{
  padding: 10px;
}
.pop_checkbox_wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .pop_checkbox{
    display: flex;
    align-items: center;
  }

  .btn_wrap{
    margin-top: 0px;

    .pop__today_close{
      background-color: transparent;
      color: #0059AE;
      text-decoration: underline;
    }
  }
}
// 팝업 - popup 끝

// 팝업용 - 개인정보처리방침 시작
.pop__overflow{
  height: 750px;
  overflow-y: auto;
}
.pop_select_wrap{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.privacy__label_title,
.privacy__list_title{
  text-align: center;
  font-weight: 600;
  color: #666;
}
.privacy__label{
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  border-bottom: none;
}
.privacy__label_item{
  flex: 0 1 33.3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  padding: 20px 10px;

  &:nth-child(3n){
    border-right: none;
  }
}
@media (max-width: 767px){
  .privacy__label_item{
    flex: 1 1 100%;
    border-right: none;
  }
}
.privacy__label_link{
  display: block;
  text-align: center;
  pointer-events: none;

  &::before{
    content: "";
    display: block;
    width: 79px;
    height: 89px;
    margin: 0 auto;
    background: url("../images/common/privacy_label01.png") no-repeat center;
  }
}
.privacy__label_link02::before{background-image: url("../images/common/privacy_label02.png");}
.privacy__label_link03::before{background-image: url("../images/common/privacy_label03.png");}
.privacy__label_link04::before{width: 83px; background-image: url("../images/common/privacy_label04.png");}
.privacy__label_link05::before{background-image: url("../images/common/privacy_label05.png");}
.privacy__label_link06::before{background-image: url("../images/common/privacy_label06.png");}
.privacy__label_link07::before{background-image: url("../images/common/privacy_label07.png");}
.privacy__label_link08::before{background-image: url("../images/common/privacy_label08.png");}
.privacy__label_link09::before{background-image: url("../images/common/privacy_label09.png");}
.privacy__label_link10::before{background-image: url("../images/common/privacy_label10.png");}
.privacy__label_link11::before{background-image: url("../images/common/privacy_label11.png");}
.privacy__label_link12::before{background-image: url("../images/common/privacy_label12.png");}
.privacy__label_link13::before{background-image: url("../images/common/privacy_label13.png");}
.privacy__label_link14::before{background-image: url("../images/common/privacy_label14.png");}

.personalInfo__list_wrap{
  >.flex{
    border: 1px solid #ddd;
    padding: 10px;
  }
}
@media (max-width: 767px){
  .personalInfo__list_wrap{
    .flex{
      display: block;
    }
  }
}
.privacy__list{
  flex: 1 1 50%;
}
.privacy__list_item{
  text-align: left;
  font-size: 12px;
}
.privacy__list_link{
  display: inline-flex;
  align-items: center;

  &::before{
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.privacy__list_link_list{
    &::before{
      display: none;
    }
  }
}
// label과 list의 아이콘 순서가 바뀐것도 있음.
.privacy__list_link01::before{background-image: url("../images/common/privacy_label01.png");}
.privacy__list_link02::before{background-image: url("../images/common/privacy_label03.png");}
.privacy__list_link03::before{background-image: url("../images/common/privacy_label02.png");}
.privacy__list_link04::before{background-image: url("../images/common/privacy_label04.png");}
.privacy__list_link05::before{background-image: url("../images/common/privacy_label05.png");}
.privacy__list_link06::before{background-image: url("../images/common/privacy_label06.png");}
.privacy__list_link07::before{background-image: url("../images/common/privacy_label08.png");}
.privacy__list_link08::before{background-image: url("../images/common/privacy_label07.png");}
.privacy__list_link09::before{background-image: url("../images/common/privacy_label09.png");}
.privacy__list_link10::before{background-image: url("../images/common/privacy_label10.png");}
.privacy__list_link11::before{background-image: url("../images/common/privacy_label11.png");}
.privacy__list_link12::before{background-image: url("../images/common/privacy_label12.png");}
.privacy__list_link13::before{background-image: url("../images/common/privacy_label13.png");}
.privacy__list_link14::before{background-image: url("../images/common/privacy_label14.png");}

.privacy_wrap{
  ol{
    margin: 10px 0px;
    padding-left: 10px;
  }
  .list_table thead tr th,
  .list_table tbody tr td{
    text-align: center;
  }
}
.privayIcon::before{
  content: "";
  display:inline-block;
  width:45px;
  height:45px;
  margin-right:10px;
  vertical-align: middle;
  background:url("../images/common/privacy_ico01.png") no-repeat;
}

.privayIcon01::before{background:url("../images/common/privacy_ico01.png")}
.privayIcon02::before{background:url("../images/common/privacy_ico02.png")}
.privayIcon03::before{background:url("../images/common/privacy_ico11.png")}
.privayIcon04::before{background:url("../images/common/privacy_ico03.png")}
.privayIcon05::before{background:url("../images/common/privacy_ico04.png")}
.privayIcon06::before{background:url("../images/common/privacy_ico05.png")}
.privayIcon07::before{background:url("../images/common/privacy_ico07.png")}
.privayIcon08::before{background:url("../images/common/privacy_ico06.png")}
.privayIcon09::before{background:url("../images/common/privacy_ico08.png")}
.privayIcon10::before{background:url("../images/common/privacy_ico09.png")}
.privayIcon11::before{background:url("../images/common/privacy_ico10.png")}
.privayIcon12::before{background:url("../images/common/privacy_ico12.png")}
.privayIcon12-1::before{background:url("../images/common/privacy_ico12-1.png")}
.privayIcon13::before{background:url("../images/common/privacy_ico13.png")}
.privayIcon14::before{background:url("../images/common/privacy_ico17.png")}


.pop__select{
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-color: #c1c6cc #e0e3ea #e0e3ea #c1c6cc;
  font-size: 100%;
  vertical-align: middle;
  height: 30px;
  color: #333;
  font-size: 12px;
  line-height: 1.3;

  option{
    padding: 0px 5px;
  }
}
// 팝업용 - 개인정보처리방침 끝

// 설문조사 시작
.survey_logo{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;

  img{
    width: 200px;
    height: 30px;
  }
}
.survey_border{
  width: 100%;
  margin: 22px 0px 0px;
  border: 1px solid #E3E3E3;
  padding: 20px;
  font-weight: 400;
  font-size: 13px;
  color: #666666;

  &.survey_border2{
    padding: 28px 20px;
  }
  
  .detail_form__title{
    font-weight: 400;
  }
}
.detail_table_title+.survey_border{
  margin-top: 6px;
}
.survey_border+.detail_table_radiobox{
  margin-top: 14px;
}
.survey_inputbox{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 36px;

  &:last-child{
    margin-bottom: 0px;
  }

  .flex+.flex{
    margin-left: 35px;
  }
}
.survey_inputbox2{
  margin-bottom: 36px;

  &:last-child{
    margin-bottom: 0px;
  }

  .flex+.flex{
    margin-top: 10px;
  }
}
.survey_inputbox3{
  display: block;
  margin-left: 20px;
  margin-bottom: 36px;

  &:last-child{
    margin-bottom: 0px;
  }

  .flex+.flex{
    margin-top: 20px;
    margin-left: 0px;

    &.align_center{
      margin-top: 10px;
    }
  }
  .align_center+.flex{
    margin-top: 10px;
  }
  .form__input{
    &.input100{
      width: 100%;
    }
    &.input_h100{
      height: 100px;
    }
    &.flex_basis80{
      flex-basis: 80%;
    }
  }
  .form__textarea{
    height: 100px;
  }
}
.survey_group_title{
  margin: 0px 0px 20px;
  border-bottom: 1px solid #DDD;
  padding-bottom: 6px;
  font-size: 14px;
  font-weight: 600;
  color: #111;
}
.survey_group_wrap{
  padding-left: 20px;
}
.survey_group_wrap+.survey_group_title{
  margin-top: 36px;
}
.survey_subtitle{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;

  &+.survey_inputbox{
    margin-top: 16px;
  }
}
.survey_text{
  margin: 0px 0px 10px 20px;
  font-size: 13px;
  color: #B6B6B6;
}
.detail_form_box{
  margin: 36px 0px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.detail_form__title{
  display: block;
  margin-bottom: 8px;
}
input[type=text].detail_form__item{
  width: 320px;
}
// 설문조사 끝


// 사이트맵 시작
.sitemap_wrap{
  margin: 20px 0px;
}
.sitemap_1dep{
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px){
  .sitemap_1dep{
    display: block;
  }
}
.sitemap__1dep_link{
  display: flex;
  align-items: center;
  width: 220px;
  height: 50px;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 14px 0px 14px 20px;
  font-weight: 600;
  font-size: 18px;
  color: #111111;
  background-color: #F5F5F5;
}
@media (max-width: 767px){
  .sitemap__1dep_link{
    width: 100%;
  }
}
.sitemap__1dep_item{
  height: 100%;
  margin-top: 30px;
  margin-right: 29px;

  &:nth-child(-n+5){
    margin-top: 0px;
  }
  &:nth-child(5n){
    margin-right: 0px;
  }
  &:last-child{margin-bottom: 0px;}
}
@media (max-width: 767px){
  .sitemap__1dep_item{
    margin-bottom: 10px;

    &:nth-child(-n+5){
      margin-right: 0px;
    }
    &:last-child{
      margin-bottom: 0px;
    }
  }
}
.sitemap_2dep{
  min-height: 240px;
  height: 100%;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  padding: 30px 0px 30px 20px;
  font-weight: 400;
  font-size: 16px;
  color: #767676;
  background: #FFFFFF;
}
@media (max-width: 767px){
  .sitemap_2dep{
    min-height: initial;
    padding: 20px;
  }
}
.sitemap__2dep_link{
  display: flex;
  align-items: center;

  &::before{
    content: "";
    display: inline-block;
    width: 2px;
    height: 2px;
    margin-right: 12px;
    border-radius: 50%;
    background-color: #767676;
  }
}
.sitemap__2dep_item{
  padding: 10px 0px;

  &:first-child{
    padding-top: 0px;
  }
  &:last-child{
    padding-bottom: 0px;
  }
}
// 사이트맵 끝


// 통합검색 시작
.search_content_wrap{
	@media (max-width: 767px){
		margin-top: 20px;
	}
}
.search_box_wrap{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 148px;
  border-radius: 20px;
  padding: 32px 30px;
  background: linear-gradient(168deg, #07427A 7.77%, #0D79E0 118.61%);
  color: #fff;

  &::after{
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 166px;
    height: 113px;
    border-radius: 20px;
    background: url("../images/common/_lnb_bg.png") no-repeat bottom right;

		@media (max-width: 767px){
			display: none;
		}
  }

  @media (max-width: 767px){
		padding: 20px 10px;
	}
}
.search_title{
  margin: 0;
  color: #FFF;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px; /* 100% */
  letter-spacing: -0.6px;

	@media (max-width: 767px){
		font-size: 16px;
	}
}

fieldset{
  padding: 0px;
}
.search_input_box{
  position: relative;
  display: flex;
  width: 540px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: #fff;
	@media (max-width: 767px){
		width: 100%;
	}
}
.search_select{
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 0 0 105px;
  width: 105px;
  height: 40px;
  border: none;
  border-radius: 20px 0px 0px 20px;
  padding: 8px 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  background: url("../images/common/ic_arrow_down.png") #fff no-repeat right 17px top 50%;
  background-color: #ffffff;
}
input.search_box__bar{
  width: 100%;
  height: 40px;
  border-radius: 0px 20px 20px 0px;
  border: none;
  padding: 10px 40px 10px 20px;
  background: #FFF;
  color: #191919;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: -0.32px;

	@media (max-width: 767px){
		padding: 10px 40px 10px 10px;
		font-size: 14px;
	}
}
.search_box__search_bar{
  position: absolute;
  top: 50%;
  right: 0px;
  width: 40px;
  height: 40px;
  border-radius: 0px 20px 20px 0px;
  background: url("../images/common/ic_view_blue.svg") no-repeat center;
  transform: translateY(-50%);
}
.search_list_wrap{
	margin-top: 52px;

	&+.search_list_wrap{
		margin-top: 80px;
	}

	.list_table {
		.list_table__title{
			overflow: hidden;
			white-space: normal;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			word-break: keep-all;
			text-align: left;
		}
	}

	.detail_attachment_wrap{
		margin-left: 0px;
		text-align: left;
		.detail_attachment{
			margin-bottom: 8px;

			.detail_attachment__link{
				display: inline-flex;
				align-items: center;
				margin-right: 0px;

				.ic_attach{
					margin-left: 0px;
					margin-right: 5px;
				}

				@media (max-width: 767px){
					font-size: 12px;
				}
			}
		}

		@media (max-width: 767px){
			margin-top: 10px;
		}
	}

	// .list_breadcrumb_wrap{
	// 	margin-top: 20px;
	// 	.breadcrumb{
	// 		max-width: 200px;
	// 		justify-content: flex-start;
	// 	}
	// }
}
.table_list_top_wrap{
	margin: 0px 0px 12px;

	@media (max-width: 767px){
		margin-bottom: 0px;
	}
}
.table_top_wrap{
	display: flex;
	align-items: center;

	border-bottom: 2px solid #666;
	padding-bottom: 15px;
}
.search_list__search_title{
	color: #111;
	font-size: 21px;
	font-weight: 700;
	line-height: normal;

	@media (max-width: 767px){
		font-size: 16px;
	}
}
.search_list__search_num{
	margin-left: 8px;
	color: #666;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px; /* 100% */
	letter-spacing: -0.28px;
	.search_list__search_num_data{
		color: #333;
		font-weight: 700;
	}

	@media (max-width: 767px){
		font-size: 12px;
	}
}

.list_table__title2{
	font-weight: 700;

	overflow: hidden;
	white-space: normal;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: keep-all;
	text-align: left;

	@media (max-width: 767px){
		font-size: 13px;
	}
}
.list_table__title_data{
	display: inline-block;
	padding: 2px;
	background-color: rgba(146, 239, 222, 0.70);
	color: #333;
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;

	@media (max-width: 767px){
		padding: 0px;
		font-size: 13px;
	}
}
.list_table__txt{
	margin: 8px 0px 0px;
	color: #666;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: -0.28px;

  &.ellipsis2{
    height: 2.5em;

    .list_table__title_data{
      padding: 1px 2px;
    }
  }

	@media (max-width: 767px){
		margin: 8px 0px;
		font-size: 13px;
	}
}
.list_table__file{
	color: #0064C3;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.28px;
	text-decoration-line: underline;
	text-align: left;

	@media (max-width: 767px){
		font-size: 13px;
	}
}
@media (max-width: 767px){
	.list_table__file+.m_list_table__content{
		margin-top: 8px;
	}
}
// 통합검색 끝




// 모바일 시작 - 크기 767px
.m_on{display: none !important;}
.table_wrap{display: block !important;}
.m_table_wrap{display: none !important;}
@media (max-width: 767px){
  .m_on{display: block !important;}
  .m_off{display: none !important;}

  // 모바일 gnb 버튼
  .m_btn__menu{
    width: 16px;
    height: 14px;
    padding: 20px;
    background-image: url(../images/common/m_ic_menu.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;

    &._on{
      background-image: url(../images/common/m_ic_close.png);
    }
  }



  // 리스트 모바일 시작
  .table_wrap{display: none !important;}
  .organization_wrap .table_wrap{display: block !important;}
  .privacy_wrap .table_wrap{display: block !important;}
  .m_table_wrap{display: block !important;}
  .m_list_table_wrap{
    font-size: 13px;

		.detail_attachment_wrap{
			.ic_attach{
				width: 8px;
				height: 12px;
				background-size: contain;
			}
		}
  }
  .m_list_table__item{
    border-bottom: 1px solid #E3E3E3;
    padding: 10px 0px;
  }
  // .m_list_table__link{
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  // }
  .m_list_table__content{
    display: flex;
    align-items: center;
    &+.m_list_table__content{
      margin-top: 5px;
    }

    &.m_list_table__content_exception{
      .m_list_table__title{
        flex: 0 0 100%;
      }
    }

		>span{
			border-left: 1px solid #999;
			padding-left: 4px;

			&:first-of-type{
				border-left: none;
				padding-left: 0px;
			}
		}
  }
  .m_list_table__title{
    flex: 0 0 50%;
    font-weight: 600;
		font-size: 13px;
  }

  // 이수자나눔공간 모바일
  .training_detail_table{
    .m_list_table_hd{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding: 12px 10px;
      background-color: #DDE2EC;

      div{
        text-align: center;
      }
    }
    .m_list_table__title{
      flex: 0 0 45%;
    }
  }

  .m_list_table__date{
    min-width: 80px;
    text-align: right;
  }
  .m_list_table__division{
    min-width: 80px;
    margin-right: 5px;
  }
  .m_list_table__branch,
	.list_table__division{
    margin-right: 5px;
  }
  .btn__application{
    width: 100px;
    height: 30px;
  }

  .m_list_table__user{
    flex: 1 1 50%;
    text-align: right;
    
    .m_list_table__date{
      min-width: 50px;
    }
    .m_list_table__nickname{
      text-align: right;
    }
  }
  // 리스트 모바일 끝


  // 공지사항 고정
  .ic_speaker{
    margin-right: 5px;
  }

  .fixed_writing{
    .m_list_table__title{
      color: #023C73;
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
}
// 모바일 끝